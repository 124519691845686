import React from 'react'
import { graphql } from 'gatsby'
import { Heading, Section, Wysiwyg, Container } from 'src/components/Base'
import {
  Hero,
  BranchesExpertise,
  AboutUs,
  ContactPerson,
  DownloadWithBackground,
} from 'src/sections'
import Seo from 'src/components/Seo'

const TemplateExpertiseSingle = ({ data }) => {
  const page = data.wpPage
  const hero = page.atspHero
  const expertise = data.wpContentNode.template.atspExpertiseSingle
  const intro = expertise.expertiseSingleIntro
  const aboutUs = expertise.expertiseSingleFiftyfifty
  const contact = expertise.expertiseSingleContact
  const contactData = (contact.contact && contact.contact.atspcontacts) || null
  const portrait =
    (contactData &&
      contactData.portrait.localFile.childImageSharp.gatsbyImageData) ||
    undefined
  const altText = (contactData && contactData.portrait.altText) || undefined
  const downloads = expertise.downloadsSection
  const downloadsIcon =
    (downloads.downloadIcon && downloads.downloadIcon.localFile.publicURL) ||
    undefined
  const downloadRepeater = downloads.downloads || undefined
  const branches = expertise.branchesOverview

  return (
    <>
      <Seo
        title={page.seo.title}
        locale={page.language.locale}
        description={page.seo.metaDesc}
      />
      <Hero imageData={hero.heroImage.localFile} heading={hero.heroHeading} />
      <Section hasBackground>
        <Heading isCentered color="dark" as="h2" size={2}>
          {intro.header}
        </Heading>
        <Container>
          <Wysiwyg isCentered>{intro.text}</Wysiwyg>
        </Container>
      </Section>
      {downloads.header && (
        <DownloadWithBackground
          downloadsBackground={
            downloads.backgroundImage.localFile.childImageSharp.gatsbyImageData
          }
          centeredHeading={downloads.headerCentered}
          heading={downloads.header}
          downloadsText={downloads.content}
          downloadsIcon={downloadsIcon}
          downloadRepeater={downloadRepeater}
        />
      )}
      <AboutUs divider heading={aboutUs.header} text={aboutUs.text} />
      {branches.brancheRepeater && (
        <BranchesExpertise
          hasBackground
          heading={branches.heading}
          brancheRepeater={branches.brancheRepeater}
          currentLanguage={page.language.locale}
        />
      )}
      {contactData && (
        <ContactPerson
          heading={contact.header}
          name={contact.contact.title}
          extensionNumber={contactData.contactData.extension}
          email={contactData.contactData.email}
          portrait={portrait}
          altText={altText}
        />
      )}
    </>
  )
}

export const pageQuery = graphql`
  query ExpertiseSingleByID($id: String!) {
    wpContentNode(id: { eq: $id }) {
      template {
        ... on WpTemplateExpertiseSingle {
          templateName
          atspExpertiseSingle {
            downloadsSection {
              header
              headerCentered
              content
              backgroundImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                  publicURL
                }
              }
              downloadIcon {
                altText
                localFile {
                  publicURL
                }
              }
              downloads {
                title
                download {
                  title
                  localFile {
                    publicURL
                  }
                }
              }
            }
            branchesOverview {
              heading
              brancheRepeater {
                heading
                label
                text
                labelColor
                link {
                  target
                  title
                  url
                }
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
            expertiseSingleContact {
              contact {
                ... on WpKontakt {
                  id
                  title
                  atspcontacts {
                    contactData {
                      email
                      extension
                    }
                    portrait {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                        publicURL
                      }
                    }
                  }
                }
              }
              header
            }
            expertiseSingleFiftyfifty {
              header
              text
            }
            expertiseSingleIntro {
              header
              text
            }
          }
        }
      }
    }
    wpPage(id: { eq: $id }) {
      language {
        locale
      }
      seo {
        title
        metaDesc
      }
      title
      atspHero {
        heroHeading
        heroImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
      }
    }
  }
`
export default TemplateExpertiseSingle
